<template>
  <div class="container-fluid">
    <b-form @submit.stop.prevent="onSubmit()">
      <div class="row">
        <div class="col-12 col-sm-6">
          <b-form-group>
            <label for="nome">Ingrediente</label>
            <b-form-input
              id="ingrediente"
              placeholder="Ingrediente"
              aria-describedby="ingrediente-invalido"
              v-model="$v.ingrediente.ingrediente.$model"
              :state="validateState('ingrediente')"
            />

            <b-form-invalid-feedback id="ingrediente-invalido">
              Ingrediente é um obrigatório!
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-12 col-sm-6">
          <b-form-checkbox
            class="mt-4"
            v-model="ingrediente.controla_estoque"
            value="1"
            unchecked-value="0"
          >
            Controlar Estoque
          </b-form-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <b-form-group>
            <label for="nome">Grupo</label>
            <b-form-select
              v-model="$v.ingrediente.grupo.$model"
              :options="grupos"
              :state="validateState('grupo')"
            />

            <b-form-invalid-feedback id="ingrediente-invalido">
              Selecione um grupo!
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div class="col-12 col-sm-3">
          <b-form-group>
            <label class="">Estoque Mínimo (alerta)</label>
            <b-form-input v-model="ingrediente.estoque_minimo" type="number" />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-3">
          <b-form-group>
            <label class="">Tipo Medida</label>
            <b-select
              v-model="ingrediente.tipo_unidade"
              :options="['UN', 'PC', 'KG', 'G', 'MG', 'L', 'ML']"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group label="Preço Venda">
            <money
              v-model="ingrediente.preco_venda"
              v-bind="moneyMask"
              class="form-control"
            />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Preço Custo">
            <money
              v-model="ingrediente.preco_custo"
              v-bind="moneyMask"
              class="form-control"
            />
          </b-form-group>
        </div>
      </div>

      <div class="row" v-if="empresasStatus">
        <div class="col">
          <StatusPorEmpresa :status="ingrediente.status" />
        </div>
      </div>

      <div
        class="bottom-actions"
        :style="`${positionUnset ? 'position: unset;' : ''}`"
      >
        <hr />
        <b-button
          type="submit"
          :disabled="loading"
          class="mr-3"
          variant="primary"
        >
          <i class="fas fa-save" v-if="!loading" />
          <b-spinner small v-if="loading" /> Salvar
        </b-button>
        <b-button :disabled="loading" variant="danger" @click="cancelar()">
          <i class="fas fa-ban" /><b-spinner small v-if="loading" /> Cancelar
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import IngredienteLib from "@/libs/IngredientesLib";
import StatusPorEmpresa from "../common/StatusPorEmpresa.vue";

export default {
  mixins: [validationMixin],
  props: {
    ingredienteEdit: { type: Object, required: false },
    positionUnset: { type: Boolean, default: false },
    addOnSave: { type: Boolean, default: false },
    empresasStatus: { type: Boolean, default: true },
  },
  components: { StatusPorEmpresa },
  data() {
    return {
      ingrediente: {
        ingrediente: "",
        cod_empresa: null,
        grupo: "",
        controla_estoque: 0,
        estoque_minimo: 0,
        preco_venda: 0,
        preco_custo: 0,
      },
      grupos: ["BORDAS", "ADICIONAIS", "INGREDIENTE"],
      loading: false,
    };
  },
  validations: {
    ingrediente: {
      ingrediente: {
        required,
      },
      grupo: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.ingrediente[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      this.$v.ingrediente.$touch();
      if (this.$v.ingrediente.$anyError) {
        return;
      }

      try {
        this.loading = true;

        this.ingrediente.cod_empresa = this.$store.state.empresas.cod_empresa;
        // console.log("ingrediente", this.ingrediente);
        const result = await IngredienteLib.store({
          ...this.ingrediente,
          preco_venda: this.formatValorReal(this.ingrediente.preco_venda),
          preco_custo: this.formatValorReal(this.ingrediente.preco_custo),
        });
        // console.log("data data", result);
        if (result && result.data && result.data.success) {
          // console.log("add on save? ", this.addOnSave);

          this.$swal.fire({
            toast: true,
            title: "Concluído",
            text: "Ingrediente salvo com sucesso!",
            icon: "success",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });

          if (this.addOnSave) {
            await this.$swal
              .fire({
                title: "Deseja adicionar o ingrediente no produto?",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Sim. Adicionar!",
                cancelButtonText: "Não.",
              })
              .then(async (res) => {
                if (res.isConfirmed) {
                  // console.log("adicionou on save", result.data.data[0]);
                  this.$emit("salvou"), this.cancelar(), this.limpar();
                  this.$emit("adicionou", result.data.data[0]);
                }
              });
          }
          
          setTimeout(() => {
            this.$emit("salvou");            
          }, 540);
          this.cancelar();
          this.limpar();
        } else {
          this.$swal.fire({
            toast: true,
            title: "Ops! Erro ao salvar! " + result.data.data.message,
            icon: "error",
            position: "top-end",
            timer: 1500,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        this.$swal.fire({
          toast: true,
          title: "Ops! Erro ao salvar! " + error,
          icon: "error",
          position: "top-end",
          timer: 1500,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      } finally {
        this.loading = false;
      }
    },
    cancelar() {
      this.$bvModal.hide("modal-ingredientes");
    },
    limpar() {
      this.ingrediente.ingrediente = "";
      (this.ingrediente.grupo = ""), (this.cod_empresa = null);
    },
  },
  mounted() {
    if (this.ingredienteEdit) {
      if (this.ingredienteEdit.cod_ingrediente) {
        Object.assign(this.ingrediente, this.ingredienteEdit);
      }
    }
  },
};
</script>
<style></style>
